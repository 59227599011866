import classNames from "classnames"
import { commonClass, primaryClass } from "components/Form/Button"
import Link from "next/link"
import { ReactNode } from "react"

export type LinkContainerProps = {
  href: string
  target?: string
  className?: string
  children?: ReactNode
  variant?: "link" | "button" | undefined
  onClick?: (e: any) => void
}

export const CustomLink = ({
  href,
  target,
  children,
  className,
  variant = "link",
  onClick,
}: LinkContainerProps) => {
  return (
    <Link
      href={href}
      target={target}
      className={classNames("transition duration-200 ease-in-out", {
        "text-primary hover:text-blue-700": variant === "link",
        [`${commonClass} ${primaryClass}`]: variant === "button",
        [`${className}`]: !!className,
      })}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
