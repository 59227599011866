export const EyeSlashIcon = ({ className, color }: IconType) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M12 15.999C14.2083 15.999 15.9985 14.2088 15.9985 12.0005C15.9985 9.79214 14.2083 8.00195 12 8.00195C9.79169 8.00195 8.0015 9.79214 8.0015 12.0005C8.0015 14.2088 9.79169 15.999 12 15.999Z"
        fill={color}
      />
      <path
        opacity="0.8"
        d="M23.2668 9.4202C21.7164 6.89513 18.1897 2.65869 12 2.65869C5.8103 2.65869 2.28361 6.89513 0.733188 9.4202C-0.244355 11.0013 -0.244355 12.9991 0.733188 14.5803C2.28361 17.1054 5.8103 21.3418 12 21.3418C18.1897 21.3418 21.7164 17.1054 23.2668 14.5803C24.2444 12.9991 24.2444 11.0013 23.2668 9.4202ZM12 17.998C8.68755 17.998 6.00223 15.3127 6.00223 12.0002C6.00223 8.68777 8.68755 6.00245 12 6.00245C15.3125 6.00245 17.9978 8.68777 17.9978 12.0002C17.9945 15.3113 15.3111 17.9947 12 17.998Z"
        fill={color}
      />
      <path d="M2 2L22 22" stroke="#2F2F2F" strokeWidth="3" />
    </svg>
  )
}
