import { checkUserStatus } from "utils/requests/checkUserStatus"

export const checkUserIsNotConfirmed = async (email: string) => {
  const userStatus = await checkUserStatus(email)

  if (!userStatus) {
    return false
  }

  return ["UNCONFIRMED"].includes(userStatus)
}
