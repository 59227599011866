export const AlertIcon = ({ className, color }: IconType) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9983 2C12.6639 2 13.278 2.35156 13.6155 2.92812L23.7405 20.1781C24.0826 20.7594 24.0826 21.4766 23.7498 22.0578C23.417 22.6391 22.7936 23 22.1233 23H1.87327C1.20296 23 0.57952 22.6391 0.246707 22.0578C-0.0861051 21.4766 -0.0814176 20.7547 0.256082 20.1781L10.3811 2.92812C10.7186 2.35156 11.3326 2 11.9983 2ZM11.9983 8C11.3748 8 10.8733 8.50156 10.8733 9.125V14.375C10.8733 14.9984 11.3748 15.5 11.9983 15.5C12.6217 15.5 13.1233 14.9984 13.1233 14.375V9.125C13.1233 8.50156 12.6217 8 11.9983 8ZM13.4983 18.5C13.4983 18.1022 13.3402 17.7206 13.0589 17.4393C12.7776 17.158 12.3961 17 11.9983 17C11.6004 17 11.2189 17.158 10.9376 17.4393C10.6563 17.7206 10.4983 18.1022 10.4983 18.5C10.4983 18.8978 10.6563 19.2794 10.9376 19.5607C11.2189 19.842 11.6004 20 11.9983 20C12.3961 20 12.7776 19.842 13.0589 19.5607C13.3402 19.2794 13.4983 18.8978 13.4983 18.5Z"
        fill={color}
      />
    </svg>
  )
}
