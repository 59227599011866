import { Button } from "components/Form/Button"
import { EyeIcon } from "components/Icons/EyeIcon/EyeIcon"
import { EyeSlashIcon } from "components/Icons/EyeSlashIcon/EyeSlashIcon"
import { useState } from "react"
import { UseFormRegisterReturn } from "react-hook-form"

export interface TextInputProps {
  id: string
  type: HTMLInputElement["type"]
  placeholder?: string
  registeredField?: UseFormRegisterReturn
  defaultValue?: string
  disabled?: boolean
  testId?: string
  onBlur?: (e: any) => void
  maxLength?: number
}

export const CONTAINER_CLASSES =
  "flex flex-row w-full rounded-xl overflow-hidden border border-gray-800 focus-within:border-primary hover:border-primary h-12 transition duration-200 ease-in-out"
export const INPUT_CLASSES =
  "px-4 text-base rounded-l-xl outline-none w-full disabled:opacity-50 disabled:cursor-not-allowed"
export const TOGGLE_BUTTON_CLASSES =
  "flex items-center justify-center cursor-pointer border-0 bg-transparent"

export const TextInput = ({
  id,
  type,
  testId,
  disabled,
  placeholder,
  registeredField,
  defaultValue,
  onBlur,
  maxLength,
}: TextInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  return (
    <div className={CONTAINER_CLASSES}>
      <input
        role="textbox"
        id={id}
        data-testid={testId}
        type={isPasswordVisible && type === "password" ? "text" : type}
        placeholder={placeholder}
        {...registeredField}
        maxLength={maxLength}
        defaultValue={defaultValue}
        className={INPUT_CLASSES}
        disabled={disabled}
        onBlur={e => {
          registeredField?.onBlur(e)

          if (onBlur) {
            onBlur(e)
          }
        }}
      />
      {type === "password" && (
        <Button
          variant="secondary"
          onClick={togglePasswordVisibility}
          className={TOGGLE_BUTTON_CLASSES}
          icon={isPasswordVisible ? EyeSlashIcon : EyeIcon}
        />
      )}
    </div>
  )
}
