import classNames from "classnames"
import { ReactNode } from "react"

interface InputContainerProps {
  id: string
  label?: string
  className?: string
  children: ReactNode
  error?: string
}

export const InputContainer = ({
  id,
  label,
  className,
  children,
  error,
}: InputContainerProps) => {
  return (
    <label
      htmlFor={id}
      className={classNames("block", {
        [`${className}`]: !!className,
      })}
    >
      {label && (
        <div
          role="aria-label"
          className="text-sm font-medium mb-2 text-neutral-content"
        >
          {label}
        </div>
      )}
      {children}
      {error && (
        <div role="alert" className="text-sm text-error mt-1">
          {error}
        </div>
      )}
    </label>
  )
}
