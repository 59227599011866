import { UserStatusType } from "@aws-sdk/client-cognito-identity-provider"

export const checkUserStatus: (
  email: string
) => Promise<null | UserStatusType> = async (email: string) => {
  const request = await fetch("/api/user-verification", {
    method: "POST",
    body: JSON.stringify({ username: email }),
    headers: {
      "Content-Type": "application/json",
    },
  })

  const data = await request.json()

  return data.userStatus as UserStatusType | null
}
