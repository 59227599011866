import { UserStatusType } from "@aws-sdk/client-cognito-identity-provider"

interface RawCognitoUser {
  Attributes: Array<{ Name: string; Value: string }>
  Enabled: boolean
  UserCreateDate: string
  UserLastModifiedDate: string
  UserStatus: UserStatusType
  Username: string
}

export interface CognitoUser {
  attributes: Map<string, string>
  enabled: boolean
  createdAtDate: string
  updatedAtDate: string
  status: UserStatusType
  userId: string
}

export const cognitoUserFactory: (
  rawUser: RawCognitoUser
) => CognitoUser = rawUser => {
  const attributesForMap = rawUser.Attributes.map(item => [
    item.Name,
    item.Value,
  ]) as any

  return {
    attributes: new Map(attributesForMap),
    enabled: rawUser.Enabled,
    createdAtDate: rawUser.UserCreateDate,
    updatedAtDate: rawUser.UserLastModifiedDate,
    status: rawUser.UserStatus,
    userId: rawUser.Username,
  }
}
